.row_card {
  display: flex;
  flex-wrap: wrap;

  .el-col {
    margin-bottom: 32px;
  }

  .box_card {
    height: 100%;
    cursor: pointer;
    border: $container-border;
    border-radius: $container-radius;

    /deep/ .el-card__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .icon {
        img {
          height: 66px;
          object-fit: cover;
        }
      }

      span {
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        font-family: 'GTWalsheimPro-Bold';
        line-height: 1.4;
        color: $primary-purple;
      }

      p {
        margin-top: 10px;
        font-size: 20px;
        color: $primary-green;
        text-transform: lowercase;
      }
    }
  }
}
